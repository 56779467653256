import React from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import * as constants from '../constants';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Details, Summary } from 'react-accessible-details';

const NoMorePage = () => {
  return (
    <Layout>
      <SEO title="No More" />
      <PageContainer>
        <h2 className="page-title">No More</h2>
        <section className="container">
          <div className="content">
            <Details>
              <Summary>Contracts Blocking Accountability</Summary>
              <p>
                All across the country, police union contracts contain language
                that blocks officer accountability for police brutality. From
                destroying records of an officer&rsquo;s past misconduct, to
                disqualifying new complaints of misconduct from being
                investigated or resulting in discipline. We must demand cities
                remove all matters of investigations, discipline, and records
                retention from the police union contracts. For example,
                Washington DC&rsquo;s council just passed legislation removing
                all disciplinary matters from the scope of police union contract
                negotiations - making police accountability non-negotiable. This{' '}
                <a href="https://www.joincampaignzero.org/s/B23-0774-Amendment1.pdf">
                  legislation
                </a>{' '}
                specified that:
              </p>
              <blockquote>
                <p>
                  &ldquo;(1) All matters pertaining to the discipline of sworn
                  law enforcement personnel shall be retained by management and
                  not be negotiable...Collective bargaining agreements are an
                  essential tool for workers to negotiate and receive fair
                  compensation, benefits, and workplace accommodations, but they
                  should not be used to shield employees from accountability,
                  particularly those employees who have as much as power as
                  police officers. This subtitle ensures that future collective
                  bargaining agreements between the Fraternal Order of
                  Police/Metropolitan Police Department Labor Committee and the
                  District of Columbia does not restrict management&#39;s right
                  to discipline sworn officers.&rdquo;
                </p>
              </blockquote>
            </Details>
            <Details>
              <Summary>Rehiring Officers Fired for Misconduct</Summary>
              <p>
                In{' '}
                <a href="https://scholarship.law.upenn.edu/cgi/viewcontent.cgi?article=9652&amp;context=penn_law_review">
                  most cities
                </a>
                , police union contracts allow officers who are disciplined or
                fired for misconduct to reduce or completely overturn these
                decisions by appealing to arbitrators. Arbitrators are lawyers
                who are not accountable to the public/voters and are chosen, in
                part, by the police union. Arbitrators{' '}
                <a href="https://www.nbc4i.com/news/u-s-world/police-in-misconduct-cases-stay-on-force-through-arbitration-police-in-misconduct-cases-stay-on-force-through-arbitration/">
                  routinely
                </a>{' '}
                reinstate officers who&rsquo;ve caused harm and been found
                guilty of police misconduct, including those who&rsquo;ve killed
                people. Cities should replace arbitrators as the final decision
                makers on police accountability with people who are liable to
                the public - for example, the city council, Mayor or community
                oversight structure. For instance, Delano, CA created a process,
                specified in their police union contract, that gives the city
                council the power to overrule decisions made by arbitrators.
                This decisive power allows the city to stop an officer from
                being reinstated after being fired for misconduct.
              </p>
            </Details>
            <Details>
              <Summary>Police Bill of Rights Laws</Summary>
              <p>
                20 states have special laws called &ldquo;Police Bill of
                Rights&rdquo; laws, that contain many of the same problematic
                provisions contained within police union contracts. These laws
                block accountability at the state-level and provide these
                problematic protections to officers at a larger scale. The vast
                majority of voters support repealing these laws in each state.
                We must demand that Governors and state legislators take action
                immediately to repeal these laws.
              </p>
            </Details>
            <Details>
              <Summary>Police Unions Influence Over Police Budgets</Summary>
              <p>
                Police unions and police union contracts contain provisions that
                can block cities from cutting their police department budget, or
                creating non-policing alternative approaches to public safety.{' '}
                <a href="https://www.policeforum.org/assets/docs/Free_Online_Documents/Budgeting/police%20department%20budgeting%20-%20a%20guide%20for%20law%20enforcement%20chief%20executives%202002.pdf">
                  85%
                </a>{' '}
                of the average police department&rsquo;s budget is spent on
                personnel costs, which means primarily paying to employ the
                officers on their force. Yet, in places like Muncie, IN, the
                police union contract requires the city to employ additional
                officers each year - limiting the ability to cut the budget.
                Other cities, like Columbus, OH, have a police union contract
                that restricts duties that are performed by law enforcement from
                being performed by a civilian employee like a mental health
                first responder. Cities should repeal these provisions, creating
                pathways for communities to reimagine and transform public
                safety.
              </p>
            </Details>
            <Details>
              <Summary>Police Union Buying Political Power</Summary>
              <p>
                Police unions have spent millions to influence and lobby
                politicians to pass laws that protect the police at the expense
                of public safety and block legislation to hold police
                accountable. Local, state and federal politicians should reject
                donations and influence from police unions, diminishing their
                power to rig the system. Similarly bar associations should act
                to restrict prosecutors from taking donations from police unions
                - a conflict of interest whereby prosecutors are funded by the
                police they are tasked with also investigating.
              </p>
            </Details>
            <Details>
              <Summary>Negotiations without Community Representation:</Summary>
              <p>
                Police union contracts are usually negotiated behind closed
                doors. Cities should include representatives from community
                organizations on the negotiating committee to ensure communities
                have a voice in the process that ultimately shapes policing in
                their city. For example, the{' '}
                <a href="https://austinjustice.org/">
                  Austin Justice Coalition
                </a>{' '}
                attended the negotiating sessions as one strategy they used to{' '}
                <a href="https://www.nytimes.com/2019/04/08/opinion/austin-police-union-contract.html">
                  hold the city accountable
                </a>{' '}
                to changing their police union contract.Their ability to attend
                the negotiations allowed a new degree of transparency for the
                community and ultimately resulted in a unanimous city council
                vote against the proposed police union contract.
              </p>
            </Details>
          </div>
        </section>
      </PageContainer>
    </Layout>
  );
};

export const PageContainer = styled.div`
  .page-title {
    text-align: center;
    margin-bottom: 1em;

    @media (max-width: 767.98px) {
      font-size: 30px;
      line-height: 1.375;
    }
  }

  .container {
    max-width: 1184px;
    margin: 0 auto;
  }

  .content {
    background: #fff;
    color: ${constants.contentTextColor};
    padding: 40px 1rem;
    border-radius: 8px;

    @media (max-width: 767.98px) {
      border-radius: 0;
    }
  }
`;

export default NoMorePage;
